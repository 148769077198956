import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

function CreationsPersonnalisees() {

    const navigate = useNavigate();
    const [btnBackHome, setBtnBackHomeClique] = useState(false);

    useEffect(() => {
        if (btnBackHome) {
            navigate("/");
        }
    }, [btnBackHome]);


    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", flexDirection: "column"}}>
            <h1>Page en cours de création, Bientôt disponible.</h1>
            <button
                className="btn-back-to-home"
                onClick={() => setBtnBackHomeClique(true)}
            >
                Retour à l'accueil
            </button>
        </div>
    );
}

export default CreationsPersonnalisees;