import {GoogleOAuthProvider} from "@react-oauth/google";
import {useState, useEffect} from "react";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Banner from "./Banner";
import Footer from "./Footer/Footer";
import ShoppingList from "../pages/ShoppingList/ShoppingList";
import DetailsProduct from "../pages/DetailsProduct/DetailsProduct";
import Login from "../pages/Login/Login";
import Profil from "../pages/Profil/Profil";
import PrivateRoutes from "../utils/PrivateRoutes";
import Register from "../pages/Register/Register";
import Apropos from "../pages/Apropos/Apropos";
import Contact from "../pages/Contact/Contact";
import Erreur404 from "../pages/Erreur404/Erreur404";
import Commandes from "../pages/Commandes/Commandes";
import Adresses from "../pages/Adresses/Adresses";
import Panier from "../pages/Panier/Panier";
import AjoutAdresse from "../pages/Ajout Adresse/AjoutAdresse";
import DetailsCommande from "../pages/DetailsCommande/DetailsCommande";
import Favoris from "../pages/Favoris/Favoris";
import HomePage from "../pages/HomePage/HomePage";
import DetailsAdresses from "../pages/DetailsAdresses/DetailsAdresses";
import Cookies from "js-cookie";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import {Analytics} from "@vercel/analytics/react"
import {SpeedInsights} from "@vercel/speed-insights/react"
import Admin from "../pages/Admin/Admin";
import AdminOrders from "../pages/Admin/AdminOrders";
import AdminCodePromo from "../pages/Admin/AdminCodePromo";
import AdminAddProduct from "../pages/Admin/AdminAddProduct";
import CheckoutDelivery from "../pages/CheckoutOrder/CheckoutDelivery";
import CheckoutPayment from "../pages/CheckoutOrder/CheckoutPayment";
import {FiltreProvider, useFiltre} from '../utils/FiltreContext';
import "./App.css";
import FiltreEtTrie from "./FiltreEtTrie/FiltreEtTrie";
import CreateOrder from "../pages/CheckoutOrder/CreateOrder";
import {pathToRegexp} from "path-to-regexp";
import "../styles/index.scss";
import PaimentSecurise from "../pages/RGPD/PaimentSecurise";
import AdminAllProducts from "../pages/Admin/AdminAllProducts";
import AdminProductInDetails from "../pages/Admin/AdminProductInDetails";
import ActivateAccount from "../pages/ActivateAccount/ActivateAccount";
import CGUV from "../pages/RGPD/CGUV";
import Retractation from "../pages/RGPD/Retractation";
import MentionsLegales from "../pages/RGPD/MentionsLegales";
import {CookieConsent} from "react-cookie-consent";
import PolitiqueConfidentialite from "../pages/RGPD/PolitiqueConfidentialite";
import CreationsPersonnalisees from "../pages/CreationsPersonnalisees/CreationsPersonnalisees";

function App() {
    const jwtToken = Cookies.get("auth_token");
    const savedCart = localStorage.getItem("cart");
    const [cart, updateCart] = useState(savedCart ? JSON.parse(savedCart) : []);
    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
        updateCart(cart);
    }, [cart]);
    const [productList, setproductList] = useState([]);
    const [minPrice, setminPrice] = useState(JSON.parse(sessionStorage.getItem('filtrePrix')) ? JSON.parse(sessionStorage.getItem('filtrePrix'))[0] : 0);
    const [maxPrice, setmaxPrice] = useState(JSON.parse(sessionStorage.getItem('filtrePrix')) ? JSON.parse(sessionStorage.getItem('filtrePrix'))[1] : 0);
    return (
        <GoogleOAuthProvider clientId={"780499566719-gncesuvhooj4t22lkgi355pflttd0pin.apps.googleusercontent.com"}>
            <FiltreProvider>
                <Router>
                    <div>
                        <FiltreEtTrieWrapper productList={productList} minPrice={minPrice} maxPrice={maxPrice}
                                             setMinPrice={setminPrice} setMaxPrice={setmaxPrice}/>
                        <AppContent cart={cart} updateCart={updateCart} productList={productList}
                                    setProductList={setproductList} minPrice={minPrice} maxPrice={maxPrice}
                                    setMinPrice={setminPrice} setMaxPrice={setmaxPrice}/>
                        <CookieConsent buttonText={"Accepter"} style={{background: "#2B373B"}}
                                       expires={365}
                                       buttonStyle={{
                                           color: "#4e503b",
                                           fontSize: "13px",
                                           background: "#f1d600",
                                           borderRadius: "5px",
                                           padding: "10px 20px",
                                           margin: "10px",
                                       }}>
                            Ce site utilise des cookies pour améliorer votre expérience utilisateur.{" "}
                        </CookieConsent>
                    </div>
                </Router>
            </FiltreProvider>
        </GoogleOAuthProvider>
    )
        ;
}

function FiltreEtTrieWrapper({productList, minPrice, maxPrice, setMaxPrice, setMinPrice}) {
    const {
        activeCategory,
        setActiveCategory,
        triageActive,
        setActiveTriage,
        minPriceForThisCategory,
        setminPriceForThisCategory,
        maxPriceForThisCategory,
        setmaxPriceForThisCategory,
        filtreValider,
        setFiltreValider
    } = useFiltre();

    return (
        <FiltreEtTrie
            activeCategory={activeCategory}
            maxPriceForThisCategory={maxPriceForThisCategory}
            minPriceForThisCategory={minPriceForThisCategory}
            setMaxPrice={setmaxPriceForThisCategory}
            setActiveTriage={setActiveTriage}
            triageActive={triageActive}
            setFiltreValider={setFiltreValider}
            setMinPrice={setminPriceForThisCategory}
            productList={productList}
            setmaxPrice={setMaxPrice}
            setminPrice={setMinPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
        />
    );
}

function AppContent({cart, updateCart, productList, setProductList, minPrice, setMinPrice, maxPrice, setMaxPrice}) {
    const {filtreOuvert, setFiltreOuvert, activeCategory, setActiveCategory} = useFiltre();
    const location = useLocation();
    const validPaths = ["/", "/informations/paiements-securise", "/informations/politique-confidentialite", "/details/:id", "/auth/login", "/profil/infos-persos", "/profil/commandes", "/profil/adresses", "/profil/favoris", "/admin", "/profil/adresses/ajoutAdresse", "/admin/orders", "/admin/promo-code", "/admin/add-product", "/admin/products", "/admin/produit/:id", "/profil/adresses/:numAdresse", "/profil/commandes/:idCommande", "/checkout/delivery", "/checkout/payment", "/checkout/success", "/auth/reset-password/:token", "/auth/register", "/apropos", "/contact","creations-personnalisees", "/informations/mentions-legales", "/informations/retractation", "/informations/conditions-generales-ventes-et-utilisations", "/informations/mentions-legales", "/panier", "/collections"].map(path => pathToRegexp(path));
    const isValidPath = validPaths.some(regex => regex.test(location.pathname));

    return (
        <>
            <div className={`${filtreOuvert ? "flou" : ""}`}>
                {console.log(location.pathname)}
                {(isValidPath && location.pathname !== '/checkout/payment' && location.pathname !== '/checkout/delivery') &&
                    <Banner/>}
                <Routes>
                    <Route exact path="/" element={<Home/>}/>{" "}
                    <Route
                        path="/Details/:id"
                        element={<DetailsProduct cart={cart} updateCart={updateCart}/>}
                    />
                    <Route path="/auth/login" element={<Login/>}/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/profil/infos-persos" element={<Profil/>}/>
                        <Route path="/profil/commandes" element={<Commandes/>}/>
                        <Route path="/profil/adresses" element={<Adresses/>}/>
                        <Route path="/profil/favoris" element={<Favoris/>}/>
                        <Route path={"/admin"} element={<Admin/>}/>
                        <Route
                            path="/Profil/adresses/ajoutAdresse"
                            element={<AjoutAdresse/>}
                        />
                        <Route path="/admin/orders" element={<AdminOrders/>}/>
                        <Route path="/admin/promo-code" element={<AdminCodePromo/>}/>
                        <Route path={"/admin/add-product"} element={<AdminAddProduct/>}/>
                        <Route path={"/admin/products"} element={<AdminAllProducts/>}/>
                        <Route path={"/admin/produit/:id"} element={<AdminProductInDetails/>}/>
                        <Route
                            path="/Profil/adresses/:numAdresse"
                            element={<DetailsAdresses/>}
                        />
                        <Route
                            path="/Profil/commandes/:idCommande"
                            element={<DetailsCommande/>}
                        />
                        <Route path="/checkout/delivery" element={<CheckoutDelivery/>}/>
                        <Route path="/checkout/payment" element={<CheckoutPayment/>}/>
                        <Route path="/checkout/success" element={<CreateOrder/>}/>
                    </Route>
                    <Route path="/auth/reset-password/:token" element={<ResetPassword/>}/>
                    <Route path="/auth/activate-account/:token" element={<ActivateAccount/>}/>
                    <Route path="/auth/register" element={<Register/>}/>
                    <Route path="/apropos" element={<Apropos/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/creations-personnalisees" element={<CreationsPersonnalisees/>}/>
                    <Route path="/informations/retractation" element={<Retractation/>}/>
                    <Route path="/informations/politique-confidentialite" element={<PolitiqueConfidentialite/>}/>
                    <Route path="/informations/conditions-generales-ventes-et-utilisations" element={<CGUV/>}/>
                    <Route path="/informations/mentions-legales" element={<MentionsLegales/>}/>
                    <Route path={"/informations/paiements-securise"} element={<PaimentSecurise/>}/>
                    <Route
                        path="panier"
                        element={<Panier cart={cart} updateCart={updateCart}/>}
                    />
                    <Route
                        path="collections"
                        element={<ShoppingList setproductList={setProductList} productList={productList}
                                               minPrice={minPrice} maxPrice={maxPrice} setMaxPrice={setMaxPrice}
                                               setMinPrice={setMinPrice}/>}
                    />
                    <Route path="/*" element={<Erreur404/>}/>
                </Routes>
                {(isValidPath && <Footer/>)}
            </div>
            <SpeedInsights/>
            <Analytics/>
        </>
    );
}

function Home() {
    return (
        <>
            <HomePage></HomePage>
        </>
    );
}

export default App;
